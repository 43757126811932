import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dataPoints = [
  { y: 32, label: 'Health' },
  { y: 22, label: 'Finance' },
  { y: 15, label: 'Education' },
  { y: 19, label: 'Career' },
  { y: 5, label: 'Family' },
];
const PieChart = ({ graphData }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: graphData?.title ? graphData?.title : 'Yearly Users Summary',
      fontSize: 13,
      fontWeight: 'normal',
      fontFamily: 'Arial',
    },
    data: [
      {
        type: 'pie',
        startAngle: 75,
        toolTipContent: '<b>{label}</b>: {y}',
        // showInLegend: 'true',
        // legendText: '{label}',
        indexLabelFontSize: 12,
        indexLabel: '{label} - {y}',
        dataPoints: graphData?.data || [],
      },
    ],
  };

  return (
    <>
      {graphData?.data?.length ? (
        <CanvasJSChart options={options} />
      ) : (
        <div className="w--full d--flex flex--column  h--full">
          <div className=" font--600 font--sm d--flex justify-content--center">
            {graphData?.title}
          </div>
          <div className="font--sm h--full d--flex justify-content--center align-items--center h-min--300">
            No Record found
          </div>
        </div>
      )}
    </>
  );
};

export default PieChart;
